import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  JeevesOrderDetailsComponent,
  JeevesOrderActionConfigService,
  JeevesOrderDetailsConfigService,
  JeevesOrderService
} from 'gung-standard-jeeves';
import {
  OrderService,
  AuthService,
  BackendInterceptor,
  ProductService,
  Order,
  BaseViewConfigService,
  ProductAssortmentParentService,
  PriceConfigService,
  GungError,
  GungModalService
} from 'gung-standard';
import { CommonModalService, DateUtilService } from 'gung-common';
import { catchError, first, forkJoin, map, of, switchMap, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'otto-olsen-order-details',
  templateUrl: './otto-olsen-order-details.component.html',
  styleUrls: ['./otto-olsen-order-details.component.scss']
})
export class OttoOlsenOrderDetailsComponent extends JeevesOrderDetailsComponent implements OnInit {
  pageTitle = '';
  public hidePrice: boolean;
  totalPrice: number;
  
  constructor(
    protected route: ActivatedRoute,
    protected orderService: JeevesOrderService,
    protected authService: AuthService,
    protected backendInterceptor: BackendInterceptor,
    protected productService: ProductService,
    public configService: JeevesOrderActionConfigService,
    protected modalService: NgbModal,
    protected orderDetailsConfig: JeevesOrderDetailsConfigService,
    public dateUtilService: DateUtilService,
    protected baseViewConfigService: BaseViewConfigService,
    protected productAssortmentParentService: ProductAssortmentParentService,
    protected titleService: Title,
    protected translateService: TranslateService,
    public priceConfigService: PriceConfigService,
    protected location: Location,
    protected commonModalService: CommonModalService,
    protected gungModalService: GungModalService,
    protected router: Router
  ) {
    super(
      route,
      orderService,
      authService,
      backendInterceptor,
      productService,
      configService,
      modalService,
      orderDetailsConfig,
      dateUtilService,
      baseViewConfigService,
      productAssortmentParentService,
      commonModalService,
      gungModalService,
      translateService,
      priceConfigService
    );
    this.priceConfigService.hidePrice.subscribe(hidePrice => (this.hidePrice = hidePrice));
  }

  ngOnInit() {
    this.authService.getRoles().subscribe(roles => {
      const filteredRoles = roles.filter(role => role.toUpperCase() === 'SALES');

      if (filteredRoles.length === 0) {
        return;
      }
      this.isSales = true;
    });

    this.route.params.pipe(
      map(params => {
        return (params.orderId as string);
      }),
      switchMap(orderId => this.orderService.getOrder(orderId, true).pipe(
        first(),
        catchError((err: HttpErrorResponse) => {
          const message = err.error?.gungMessage || err.error?.message || 'ERROR';
          const gungError: GungError = {
            ...new Error(message),
            i18nKeyMessage: message
          };
            
          this.commonModalService.openGungErrorHandler(gungError);

          return throwError(() => new Error(message));
        }))
      )
    ).subscribe(order => {
      const mappedOrder = this.mapOrder(order);
      this.order = mappedOrder;
      const productIds = mappedOrder.rows.map(row => row.productId);
      this.totalPrice = mappedOrder.rows.reduce((total, row) => total + row.extra.orp.vbordradsum, 0);
      this.pageTitle = this.translateService.instant('OTTO_OLSEN_ORDER_DETAIS', {
        order: order.id,
        customerOrderId: order.extra.oh.kundbestnr || 'No number'
      });
      this.titleService.setTitle(this.pageTitle);

      this.productService.getProductsByIds(productIds).subscribe(products => {
        this.products = {};
        products.forEach(product => {
          this.products[product.id] = product;
        });
      });
    });
  }

  protected mapOrder(order: Order): Order {
    return order;
  }

  navigateBackwards() {
    if (document?.referrer) {
      this.location.back();
    } else {
      // this.router.navigate(['/orders']);
      this.router.navigate(['/customers', this.order.billingCustomerId ], 
      {
        queryParams: {
          tabId: 'lib-gung-tab-4'
        }
      });
    }
  }
}
