import { Component, Input } from '@angular/core';
import { AuthService, Availability, AvailabilityService, GungModalService } from 'gung-standard';
import { first, forkJoin, map } from 'rxjs';
import { OttoOlsenAvailabilityDisplay, OttoOlsenAvailabilityHelperService } from '../../services/otto-olsen-availability-helper.service';
import { OttoOlsenAvailabilityComponent } from '../otto-olsen-availability/otto-olsen-availability.component';
import { User } from 'gung-standard/lib/state/auth/types';
import { gungComparatorHelper } from 'gung-common';

@Component({
  selector: 'otto-olsen-availability-warehouses',
  templateUrl: './otto-olsen-availability-warehouses.component.html',
  styleUrls: ['./otto-olsen-availability-warehouses.component.scss']
})
export class OttoOlsenAvailabilityWarehousesComponent extends OttoOlsenAvailabilityComponent {

  currentUser: User;
  displayStockIds: string[];

  public warehouses = {
    '30': 'Hovedlager',
    '17': 'Kristiansand',
    '15': 'Alesund'
  };
  warehouseStock: { [id: string]: Availability } = {};
  warehouseStockString: { [id: string]: OttoOlsenAvailabilityDisplay } = {};
  warehouseStockSorted: {
    stockId: string;
    warehouses: string;
    availability: OttoOlsenAvailabilityDisplay;
  }[] = [];

  @Input()
  mobileModal = false;

  @Input()
  productReplace = false;

  constructor(
    protected availabilityHelper: OttoOlsenAvailabilityHelperService,
    protected gungModalService: GungModalService,
    protected availabilityService: AvailabilityService,
    protected authService: AuthService
  ) {
    super(availabilityHelper, gungModalService);
    this.authService.getCurrentUser().pipe(first()).subscribe(currentUser => {
      this.currentUser = currentUser;
      let displayStockIds = [...(currentUser.managedMultistockIds || [])];
      if (this.currentUser.extra.displayStockIds) {
        displayStockIds.push(...this.currentUser.extra.displayStockIds);
      }
      displayStockIds = [...new Set(displayStockIds)];
      this.displayStockIds = displayStockIds;
    })
  }

  ngOnInit() {

    if (this.displayStockIds?.length > 0) {
      const request = this.displayStockIds.reduce((prev, curr) => ({ ...prev, [curr]: this.availabilityService.getAvailability(this.availability.productId, curr).pipe(first()) }), {});
      forkJoin(
        request
      ).subscribe(availability => {
        this.warehouseStock = availability;

        const request2 = Object.keys(this.warehouseStock).reduce((prev, curr) => {
          return {
            ...prev,
            [curr]: this.availabilityHelper.getAvailabilityDisplay(this.warehouseStock[curr], null, this.unit, this.details, this.decimals, this.productionDays).pipe(first())
          };
        }, {});
        forkJoin(
          request2
        ).subscribe((availabilityDisplay: { [id: string]: OttoOlsenAvailabilityDisplay }) => {
          this.warehouseStockString = availabilityDisplay;
          let sortable: {
            stockId: string;
            warehouses: string;
            availability: OttoOlsenAvailabilityDisplay;
          }[] = [];
          for (var stockId in this.warehouseStockString) {
            sortable.push({
              stockId,
              warehouses: this.warehouses[stockId],
              availability: this.warehouseStockString[stockId]
            });
          }
          this.warehouseStockSorted = sortable.sort((a, b) => {
            const warehouseExpediteId = this.displayStockIds[0]; // Asked in trello to confirm this - https://trello.com/c/NFZZ5Jt6/1100-user-detail-page-edits-and-edit-how-the#comment-669a2bf91b3fa576f214f00b
            if (a.stockId === warehouseExpediteId) {
              return -1;
            }
            if (b.stockId === warehouseExpediteId) {
              return 1;
            }
            return gungComparatorHelper(a.stockId, b.stockId, 1, this.displayStockIds);
          });
        });
      });
    }

    this.availabilityHelper
      .getAvailabilityDisplay(this.availability, null, this.unit, this.details, this.decimals, this.productionDays)
      .pipe(first())
      .subscribe(d => {
        this.status = d.value;
        this.class = d.class;
        this.tooltip = d.tooltip;
        this.addicionalInfo = d.addicionalInfo;
      });
  }
}
