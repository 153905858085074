import { Inject, Injectable, Optional } from '@angular/core';
import {
  NavbarConfigService,
  AuthService,
  GungFlowService,
  AssortmentService,
  SelectedCustomerService,
  NavGroup,
  NavLink,
  GungModalService,
  CartService,
  CustomerService,
  NewsActionConfigService,
  NavAction,
  BackendInterceptor,
  DocumentsService,
  PriceConfigService,
  Customer,
  BaseViewConfigService,
  GungCompanyService,
  NavigationConfig,
  LocationConfigService,
  MetadataService,
  SelectedSupplierService,
  GungFeatureMap,
  GungNavbarService
} from 'gung-standard';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { forkJoin, Observable, of, Subject, takeUntil, tap } from 'rxjs';
import { filter, first, mergeMap, map } from 'rxjs';
import { Location } from '@angular/common';
import { OttoOlsenPimEditConfigService } from './otto-olsen-pim-edit-config.service';
import { OttoOlsenRoleSwitcherHelperService } from './otto-olsen-role-switcher-helper.service';
import { BackendFeatureService } from 'gung-standard';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenNavbarConfigService extends NavbarConfigService {
  enableCreateProduct = true;
  enableAccountRequestManage = true;
  isEnabledShowUserNewsInPage = false;
  isViewOnly = false;
  isScannerOnly = false;
  enableQuickorderScanner = true;
  enableBarcodeScanner = true;
  isAdmin = false;
  unsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(
    protected authService: AuthService,
    protected gungFlowService: GungFlowService,
    protected translateService: TranslateService,
    protected selectedCustomerService: SelectedCustomerService,
    protected router: Router,
    protected gungModalService: GungModalService,
    protected cartService: CartService,
    protected customerService: CustomerService,
    protected newsActionConfigService: NewsActionConfigService,
    protected backendInterceptor: BackendInterceptor,
    protected documentsService: DocumentsService,
    protected priceConfigService: PriceConfigService,
    protected location: Location,
    protected baseViewConfigService: BaseViewConfigService,
    protected gungCompanyService: GungCompanyService,
    protected assortmentService: AssortmentService,
    @Optional()
    @Inject('environment')
    protected environment: NavigationConfig,
    protected locationConfigService: LocationConfigService,
    protected metadataService: MetadataService,
    protected selectedSupplierService: SelectedSupplierService,
    protected ottoOlsenPimEditConfigService: OttoOlsenPimEditConfigService,
    protected ottoOlsenRoleSwitcherHelperService: OttoOlsenRoleSwitcherHelperService,
    protected backendFeatureService: BackendFeatureService,
    protected http: HttpClient,
    protected gungNavbar: GungNavbarService
  ) {
    super(
      authService,
      gungFlowService,
      translateService,
      selectedCustomerService,
      router,
      gungModalService,
      cartService,
      customerService,
      newsActionConfigService,
      backendInterceptor,
      documentsService,
      priceConfigService,
      location,
      baseViewConfigService,
      gungCompanyService,
      assortmentService,
      environment,
      locationConfigService,
      metadataService,
      selectedSupplierService,
      backendFeatureService,
      http,
      gungNavbar
    );
    this.authService.getHasRoles('VIEW').subscribe(isViewOnly => (this.isViewOnly = isViewOnly));
    this.authService
      .getHasRoles('SCANNER')
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(isScannerOnly => (this.isScannerOnly = isScannerOnly));
    this.authService.getHasRoles('ADMIN').subscribe(admin => (this.isAdmin = admin));
  }

  public getMainMenu(): Observable<NavGroup> {
    return super.getMainMenu().pipe(
      mergeMap(menu =>
        forkJoin([
          of(menu),
          this.customerService.getCustomers().pipe(first()),
          this.authService.getRoles().pipe(first())
        ])
      ),
      map(([menu, customers, userRoles]) => {
        const idxDeliveryLocation = (menu.links[0] as NavGroup).links.findIndex(m => m.heading === 'DELIVERY_LOCATION');
        if (idxDeliveryLocation > -1) {
          if (customers.length < 2) {
            (menu.links[0] as NavGroup).links.splice(idxDeliveryLocation, 1);
          } else {
            (menu.links[0] as NavGroup).links[idxDeliveryLocation].heading = 'DELIVERY_LOCATION_MENU';
          }
        }
        /* const logout = (menu.links[1] as NavGroup).(m => m.heading === 'LOGOUT'); */
        if (menu.links[1].heading === 'LOGOUT') {
          menu.links[1].icon = 'fa-arrow-right-from-bracket';
        }
        if (this.ottoOlsenPimEditConfigService.hasPimEditUrlsRoles(userRoles)) {
          const idxHidePrice = menu.links.findIndex(m => m.heading === 'HIDE_PRICE');
          const showEditPim = {
            link: undefined,
            heading: 'HIDE_EDIT_PIM_LINKS',
            action: (inputElement: HTMLInputElement) => {
              this.ottoOlsenPimEditConfigService.pimEditUrlHide.next(inputElement.checked);
            }
          };
          menu.links.splice(idxHidePrice > -1 ? idxHidePrice : 0, 0, showEditPim);
        }

        return menu;
      })
    );
  }

  public getLeftMenus(): Observable<NavGroup[]> {
    const baseMenu: NavGroup[] = [
      // {
      //   heading: 'QUICK_SEARCH',
      //   link: '/quick-products'
      // },
    ];

    return this.authService.getCurrentUser().pipe(
      filter(user => !!user),
      first(),
      mergeMap(user => {
        return forkJoin([
          this.assortmentService.getAssortmentRecursive(user.assortment).pipe(first()),
          user.roles.includes('ANONYMOUS')
            ? of(null)
            : this.selectedCustomerService.getSelectedCustomer().pipe(
                filter(customer => !!customer),
                first()
              )
        ]);
      }),
      map(([assortment, customer]) => {
        const productsMenu = {
          heading: 'PRODUCTS',
          link: this.getProductListBaseRoute(),
          links: undefined
        } as NavGroup;
        if (assortment && assortment.children) {
          productsMenu.links = assortment.children.map(
            childAssortment =>
              ({
                heading: childAssortment.name,
                link: `/articles/${childAssortment.id}`,
                links: childAssortment.children.map(
                  subChildAssortment =>
                    ({
                      heading: subChildAssortment.name,
                      link: `/articles/${childAssortment.id}/${subChildAssortment.id}`
                    } as NavLink)
                )
              } as NavGroup)
          );
        }
        baseMenu.unshift(productsMenu);
        if (customer) {
          baseMenu.push({
            link: '/favourites',
            heading: 'FAVOURITES'
          });
        }
        return baseMenu;
      })
    );
  }

  protected getAdminMenu(): (NavLink | NavAction | NavGroup)[] {
    const menuLinks = super.getAdminMenu();

    menuLinks.unshift({
      heading: '',
      links: [
        {
          heading: 'PDF_TO_CART',
          link: '/pdf-to-cart'
        }
      ]
    });

    // Add BETA QUICKORDER SCANNER
    if (this.enableQuickorderScanner) {
      (menuLinks[0] as NavGroup).links.push({
        link: '/barcode-scanner',
        heading: 'BARCODE_SCANNER',
        icon: 'fa-barcode'
      });
      // (menuLinks[0] as NavGroup).links.splice((menuLinks[0] as NavGroup).links.length, 0, {
      //   link: '/quickorder-scanner',
      //   heading: 'BARCODE_SCANNER_QUICKORDER'
      // });
    }

    return menuLinks;
  }

  protected getUserMenu(customer: Customer): (NavLink | NavAction | NavGroup)[] {
    const menuLinks = this.getOttoUserMenu(customer) as NavGroup[];

    let previousOrdersIndex = (menuLinks[0] as NavGroup).links.findIndex(m => m.heading === 'PREVIOUS_ORDERS');

    if (this.isAdmin) {
      // Add digital assets to the project
      (menuLinks[0] as NavGroup).links.splice(++previousOrdersIndex, 0, {
        link: '/products-export',
        heading: 'PRODUCT_EXPORT_NAV_BAR'
      });
    }

    if (!!customer?.extra?._customerSpecAssortment) {
      menuLinks[0].links.push({
        heading: 'EXTRA_PRODUCTS',
        link: `/products/a/${customer.extra._customerSpecAssortment}`,
        icon: 'fa-drum-steelpan'
      });
    }
    menuLinks[0].links.push({
      link: '/customers-dashboard',
      heading: 'CUSTOMER_DASHBOARD',
      icon: 'fa-objects-column'
    });
    if ((menuLinks[0] as NavGroup).links.findIndex(m => m.heading === 'PRODUCT_EXPORT_NAV_BAR') > -1) {
      menuLinks[0].links.splice(
        (menuLinks[0] as NavGroup).links.findIndex(m => m.heading === 'PRODUCT_EXPORT_NAV_BAR'),
        1
      );
    }
    // if ((menuLinks[0] as NavGroup).links.findIndex(m => m.heading === 'SHOP_BY_PRODUCT_LIST') > -1) {
    //   menuLinks[0].links.splice((menuLinks[0] as NavGroup).links.findIndex(m => m.heading === 'SHOP_BY_PRODUCT_LIST'), 1);
    // }

    if (this.isViewOnly && (menuLinks[0] as NavGroup).links.findIndex(m => m.heading === 'SAVED_CARTS') > -1) {
      menuLinks[0].links.splice(
        (menuLinks[0] as NavGroup).links.findIndex(m => m.heading === 'SAVED_CARTS'),
        1
      );
    }

    if (this.isScannerOnly) {
      const barcodeScannerItem = {
        link: '/barcode-scanner',
        heading: 'BARCODE_SCANNER',
        icon: 'fa-barcode',
        action: () => {
          this.ottoOlsenRoleSwitcherHelperService.setRole('SCANNER');
        }
      };

      (menuLinks[0] as NavGroup).links.push(barcodeScannerItem);
    }

    return menuLinks;
  }

  protected getOttoUserMenu(
    customer: Customer,
    isSales?: boolean,
    customers?: Customer[],
    isActuator?: boolean,
    features?: GungFeatureMap
  ): (NavLink | NavAction | NavGroup)[] {
    const menuLinks: NavGroup[] = [
      {
        heading: '',
        links: [
          {
            link: `${'/customers/' + customer.id}`,
            heading: 'MY_PAGES',
            icon: 'fa-address-card'
          },
          {
            link: '/delivery-location',
            heading: 'DELIVERY_LOCATION',
            icon: 'fa-location-dot'
          },
          {
            link: '/orders',
            heading: 'PREVIOUS_ORDERS',
            icon: 'fa-memo'
          },
          {
            link: '/products-export',
            heading: 'PRODUCT_EXPORT_NAV_BAR',
            icon: 'fa-file-export'
          },
          {
            link: '/carts',
            heading: 'SAVED_CARTS',
            icon: 'fa-cart-shopping'
          },
          {
            link: '/saved-quotes',
            heading: 'SAVED_QUOTES'
          },
          {
            link: '/favourites',
            heading: 'FAVOURITES',
            icon: 'fa-star'
          }
        ]
      }
    ];

    if (!isSales) {
      // Remove SAVED_QUOTES if not SALES
      menuLinks[0].links.splice(
        (menuLinks[0] as NavGroup).links.findIndex(m => m.heading === 'SAVED_QUOTES'),
        1
      );
    } else {
      menuLinks[0].links.splice(
        (menuLinks[0] as NavGroup).links.findIndex(m => m.heading === 'MY_PAGES'),
        1
      );
      menuLinks[0].links.splice(
        (menuLinks[0] as NavGroup).links.findIndex(m => m.heading === 'DELIVERY_LOCATION'),
        1
      );
    }

    if (
      customers &&
      customers.length < 2 &&
      (menuLinks[0] as NavGroup).links.findIndex(m => m.heading === 'DELIVERY_LOCATION') > -1
    ) {
      // Remove DELIVERY_LOCATION if only one delivery location
      menuLinks[0].links.splice(
        (menuLinks[0] as NavGroup).links.findIndex(m => m.heading === 'DELIVERY_LOCATION'),
        1
      );
    }

    let favouritesIndex = (menuLinks[0] as NavGroup).links.findIndex(m => m.heading === 'FAVOURITES');
    if (this.enableDigitalAssets) {
      // Add digital assets to the project
      (menuLinks[0] as NavGroup).links.splice(++favouritesIndex, 0, {
        link: '/digital-assets',
        heading: 'DIGITAL_ASSETS'
      });
    }

    if (this.enableActivityInternal) {
      // Add activity internal to the project
      (menuLinks[0] as NavGroup).links.splice(++favouritesIndex, 0, {
        link: '/activity-internal',
        heading: 'ACTIVITIES'
      });
    }

    if (this.isEnabledShowUserNewsInPage) {
      (menuLinks[0] as NavGroup).links.push({ link: '/news', heading: 'NEWS' });
    }

    if (this.enableWhiteboardManagement) {
      (menuLinks[0] as NavGroup).links.push({ link: '/whiteboard-management', heading: 'WHITEBOARD' });
    }

    if (this.enableExportPdf) {
      (menuLinks[0] as NavGroup).links.push({
        link: '/export-pdf',
        heading: 'WHITEBOARD',
        action: () => this.router.navigate(['export-pdf'], { queryParams: { filters: 'ARCHIVED__:__NO' } })
      });
    }

    if (this.enableDocumentUserGroups) {
      this.documents.forEach(doc => {
        const url = this.backendInterceptor.getBaseUrl() + '/gung-document-archive/document/' + doc.id;
        menuLinks[0].links.push({
          heading:
            doc.metaData.description && doc.metaData.description !== 'undefined'
              ? doc.metaData.description
              : doc.filename,
          action: () => {
            window.open(url, '_blank');
          }
        });
      });
    }

    if (
      this.hasFeatureWithVersion(features, 'reportCentral', undefined) &&
      this.featureVisibleForRole(features, 'reportCentral', 'USER')
    ) {
      menuLinks[0].links.push({
        link: '/report-central',
        heading: 'REPORT_CENTRAL'
      });
    }

    return menuLinks;
  }

  protected getSalesMenu(
    isActuator?: boolean,
    roles?: string[],
    salesCodeErp?: string
  ): (NavLink | NavAction | NavGroup)[] {
    const menuLinks: NavGroup[] = super.getSalesMenu(isActuator, roles, salesCodeErp);
    const idx = (menuLinks[0] as NavGroup).links.findIndex(m => m.heading === 'QUICK_ORDER');
    menuLinks[0].links.splice(idx > -1 ? idx : 0, 0, { link: '/quick-search', heading: 'QUICK_SEARCH' });
    (menuLinks[0] as NavGroup).links.splice((menuLinks[0] as NavGroup).links.length, 0, {
      link: '/admin/create-product',
      heading: 'CREATE_PRODUCT'
    });
    const idy = (menuLinks[0] as NavGroup).links.findIndex(m => m.heading === 'SALES_DASHBOARD');
    menuLinks[0].links.splice(idy > -1 ? idy : 0, 0, {
      link: '/sales-dashboard-beta',
      heading: 'SALES_DASHBOARD_BETA'
    });

    if (roles.indexOf('ADMIN') === -1) {
      menuLinks[0].links.push({
        link: '/sales/manage-users',
        heading: 'USERS'
      });
    }

    return menuLinks;
  }

  public getTopInformation(): Observable<NavLink> {
    return this.userRoles().pipe(
      first(),
      mergeMap(roles =>
        forkJoin([
          this.selectedCustomerService.getSelectedCustomer().pipe(
            filter(customer => !!customer),
            first()
          ),
          of(roles.indexOf('ANONYMOUS') > -1),
          this.authService.getCurrentUser().pipe(first())
        ])
      ),
      map(([customer, isAnonymous, user]) => {
        if (this.isScannerOnly) {
          return {
            heading: customer.name + ' ' + customer.id + '<br>' + user.email,
            link: `/customers/${customer.id}`
          } as NavLink;
        }
        if (isAnonymous) {
          return null;
        }
        return {
          heading: customer.name + ' ' + customer.id,
          link: `/customers/${customer.id}`
        } as NavLink;
      })
    );
  }

  priceMenu(): Observable<NavGroup> {
    return this.userRoles().pipe(
      map(roles => {
        if (!roles) {
          return null;
        } else {
          if (roles.indexOf('SALES') > -1 || roles.indexOf('ADMIN') > -1) {
            if (this.priceConfigService.enableHidePrice) {
              return {
                link: undefined,
                heading: 'HIDE_PRICE',
                action: (inputElement: HTMLInputElement) => {
                  this.priceConfigService.setHidePrice(inputElement.checked);
                }
              };
            }
          }
        }
      })
    );
  }
}
