<div class="in-cart-container">
  <div class="row mt-2" *ngIf="productsInCart && productsInCart.length > 0 && !showProductDetails">
    <div class="col-12 p-0">
      <div class="table-responsive">
        <h5 class="text-center m-0" translate>CART_CONTENT</h5>
        <table class="table mt-3">
          <tbody *ngFor="let productInCart of productsInCart; trackBy: trackByProductsInCart; let i = index">
            <tr [ngStyle]="{ 'background-color': i % 2 === 0 ? '#e5e5e5' : '#ffffff' }" class="border-bottom-0">
              <td rowspan="1" class="border-bottom-0 px-2" style="width: 1%; vertical-align: middle">
                <otto-olsen-availability
                  showOnlyIcons="true"
                  [isSales]="true"
                  [availability]="productInCart.extra.availabilities"
                  [quantityInCart]="productInCart.qty"
                >
                </otto-olsen-availability>
              </td>
              <td rowspan="1" class="border-bottom-0" style="min-width: 45px; vertical-align: middle">
                <img
                  *ngIf="
                    productInCart.extra.product.extra.images && productInCart.extra.product.extra.images[0] as image;
                    else noimage
                  "
                  class="img-fluid m-auto"
                  src="https://cdn1.gung.io/fit-in/50x50/filters:etag({{
                    productInCart.extra.product.extra.images[0].s3ETag
                  }})/{{ productInCart.extra.product.extra.images[0].s3Uri }}"
                  alt=""
                />
                <ng-template #noimage>
                  <img
                    class="card-img-top img-fluid w-100"
                    src="https://d27ahaa1qqlr90.cloudfront.net/fit-in/300x300/images/no-image.jpg"
                    alt="NO IMAGE"
                    class="img-fluid mx-auto d-block"
                  />
                </ng-template>
                <br>
                <button type="button" class="btn btn-link p-0 btn-remove" (click)="remove(productInCart.extra.product, productInCart.qty)">Slett vare</button>
              </td>
              <td class="border-bottom-0 w-50 px-2" style="vertical-align: middle">
                <p class="info-product-text m-0">{{ productInCart.extra.product.id }}</p>
                <p class="info-product-text m-0">{{ productInCart.extra.product.name }}</p>
              </td>

              <td colspan="2" class="border-bottom-0" style="vertical-align: middle; min-width: 140px">
                <otto-olsen-buy-btn
                  name="buy-btn-package-size"
                  [id]="productInCart.extra.product.id"
                  [checkoutCart]="true"
                  [minimumOrderQuantity]="productInCart.product?.firstStepAmount || productInCart.product?.packageSize"
                  [stepAmount]="productInCart.extra?.product?.packageSize"
                  [originalSize]="productInCart.extra?.product?.originalSize"
                  [productPartialId]="productInCart.productPartialId"
                  [decimals]="productInCart.extra?.product?.extra.ar.antdec"
                  [activeInB2B]="true"
                  [product]="productInCart.extra.product"
                  [availability]="productInCart.extra.availabilities"
                >
                  <!-- They want products to be purchasable in the scanner, no matter if active in B2B or not https://support.gung.se/a/tickets/246248 -->
                </otto-olsen-buy-btn>
                @if(!productInCart.extra?.product?.packageSize) {
                  <div class="mt-1 d-flex justify-content-between">
                    <button
                      type="button"
                      class="btn btn-sm btn-primary button-size-l"
                      (click)="
                        subtract(
                          productInCart.extra.product,
                          productInCart.extra.availabilities,
                          number(productsInCart.qty),
                          number(productInCart.extra?.product?.packageSize || 1) * 10
                        ); $event.preventDefault(); $event.stopPropagation()
                      "
                    >
                      -{{ number(productInCart.extra?.product?.packageSize || 1) * 10 }}
                    </button>
                    <span class="d-flex align-items-center">{{ productInCart.extra?.product.extra.ar?.enhetskod }}</span>
                    <button
                      type="button"
                      class="btn btn-sm btn-primary button-size-r"
                      (click)="
                        add(
                          productInCart.extra.product,
                          productInCart.extra.availabilities,
                          number(productInCart.qty),
                          number(productInCart.extra?.product?.packageSize || 1) * 10
                        ); $event.preventDefault(); $event.stopPropagation()
                      "
                    >
                      +{{ number(productInCart.extra?.product?.packageSize || 1) * 10 }}
                    </button>
                  </div>
                } @else {
                  <span class="d-block w-100 text-center">Fast pakkestr. {{productInCart.extra?.product?.packageSize}} {{ productInCart.extra?.product.extra.ar?.enhetskod }}</span>
                }
              </td>
            </tr>
            @if(productInCart.extra.availabilities.currentStock !== 0 && productInCart.qty > productInCart.extra.availabilities.currentStock) {
              <tr [ngStyle]="{ 'background-color': i % 2 === 0 ? '#e5e5e5' : '#ffffff' }" class="border-bottom-0">
                <td colspan="6" class="border-bottom-0">
                  <span class="ml-2 text-danger cursor-pointer" (click)="openErrorModal(productInCart.extra?.product, productInCart.extra.availabilities, productInCart.qty)">
                    <i class="fa-solid fa-circle-info text-warning"></i>
                    {{ 'ONLY_SOME_IN_STOCK' | translate: {currentStock: productInCart.extra.availabilities.currentStock, qty: productInCart.qty, date: getavailabelDate(productInCart.extra.availabilities, productInCart.qty)} }}
                  </span>
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div *ngIf="showProductDetails" class="product-detail">
    <div class="close-detail" (click)="showProductDetails = !showProductDetails"><i class="fas fa-times"></i></div>
    <ng-template libCustomHost></ng-template>
  </div>
</div>
<otto-olsen-scanner-footer-menu></otto-olsen-scanner-footer-menu>
