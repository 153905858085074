<div *ngIf="edit && rootAssortment" class="row">
  <div class="col">
    <div class="form-group">
      <button type="button" class="btn btn-primary" (click)="openModal('prefixUrl')">PREFIXURL</button>
    </div>
  </div>
</div>
<div class="">
  <div
    class="header-wrapper image-container d-flex flex-column justify-content-center"
    [ngStyle]="{ 'background-image': 'url(\'' + this.getImage(data.elements?.topImage?.image) + '\')' }"
    [class.no-cursor]="!edit"
    (click)="edit ? openModal('topImage') : navigateTo(data.elements?.topImage?.link)"
  >
    <div class="header-text" translate>
      {{ getText(data.elements?.topImage?.text) }}
    </div>
    <div>
      <button
        class="btn header-button"
        (click)="
          $event.preventDefault();
          $event.stopPropagation();
          edit ? openModal('topButton') : navigateTo(data.elements?.topButton.link)
        "
        translate
      >
        {{ getText(data.elements?.topButton?.text) }}
      </button>
    </div>
  </div>
</div>
<button *ngIf="edit" (click)="openModalTextInput('assortment')" class="image-change-button btn btn-primary mt-2 float-right" translate>CHANGE_ASSORTMENT</button>
<div class="" *ngIf="rootAssortment && selectedAssortment">
  <h1 class="mt-5 mb-4" translate>SELECT_CATEGORY</h1>
  <div class="row">
    <div class="col-12 col-sm-6 col-md-3 mb-2 assortment" *ngFor="let assortment of selectedAssortment.children">
      <!-- <a [routerLink]="!edit ? ('/' + prefixUrl + '/' + assortment.id) : undefined">
        <div
          class="category-image"
          [ngStyle]="getStyle(assortment)"
          [class.no-cursor]="edit"
        >
          <div class="text-banner">
            {{ assortment.name }}
          </div>
        </div>
      </a> -->
      <a class="card h-100" [routerLink]="['/articles/', assortment.id]">
        <div class="card-body d-flex flex-column justify-content-between">
          <h5 class="card-title text-left">{{ assortment.name }}</h5>
          <p class="card-text text-left">{{ assortment.description }}</p>
          <span class="card-link border-0 align-self-start">
            <i class="mt-auto fa-2x text-primary fas fa-long-arrow-alt-right"></i>
          </span>
        </div>
      </a>
    </div>
  </div>
</div>
