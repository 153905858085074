import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { OrderConfirmationContactsService } from '../../services/order-confirmation-contacts.service';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FormUtilService } from 'gung-common';

@Component({
  selector: 'lib-confirmation-emails',
  templateUrl: './confirmation-emails.component.html',
  styleUrls: ['./confirmation-emails.component.css']
})
export class ConfirmationEmailsComponent implements OnInit {
  @Input()
  set emails(emails: string[]) {
    this._emails = emails;
    if (this.form) {
      this.updateEmails(emails);
    }
  }

  get emails(): string[] {
    return this._emails;
  }

  _emails: string[] = [];

  @Input()
  getUserContacts: boolean = true;

  loading = true;

  @Input()
  public disabled = false;

  @Output()
  emailsUpdated = new EventEmitter<string[]>();

  @Output()
  formValid = new EventEmitter<boolean>();

  public form: FormGroup;

  @Input()
  public labelTranslation = 'EMAIL_CONFIRMATION';

  constructor(
    protected orderConfirmationContactService: OrderConfirmationContactsService,
    protected formBuilder: FormBuilder,
    protected formUtilService: FormUtilService
  ) { }

  ngOnInit() {
    if (!this.emails) {
      this.emails = [];
    }
    this.form = this.formBuilder.group({
      emails: this.formBuilder.array([]) // Initialize an empty FormArray
    });
    this.form.get('emails').valueChanges.subscribe(changes => {
      if (this.loading) {
        return;
      }
      this.emitEmails();
    });

    if (this.emails.length === 0 && this.getUserContacts) {
      this.orderConfirmationContactService.getOrderConfirmationContacts().subscribe(contacts => {
        const emails = contacts.map(c => c.email);
        emails.forEach(email => this.addEmail(email));
        this.loading = false;
      });
    } else {
      this.emails.forEach(email => this.addEmail(email, true));
      this.loading = false;
    }
  }

  updateEmails(emails: string[]) {
    const formEmails: string[] = this.controlsEmail.controls/* .filter(control => (control as FormGroup).controls.email.valid) */.map(control => control.value.email);
    let newEmails = emails.filter(email => formEmails.indexOf(email) < 0);
    for (const email of newEmails) {
      this.addEmail(email.trim(), true)
    }
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
  }

  removeEmail(i: number) {
    this.controlsEmail.removeAt(i);
    this.emitEmails();
  }

  addEmail(value: string = '', skipEmit = false) {
    const email = this.formBuilder.group({
      email: this.formBuilder.control(value, {
        updateOn: 'blur',
        validators: [Validators.required, ...this.formUtilService.emailValidators]
      })
    });
    this.controlsEmail.push(email);
    if (!skipEmit) {
      this.emitEmails();
    }
  }

  get controlsEmail() {
    return this.form.get('emails') as FormArray;
  }

  emitEmails() {
    this.form.markAllAsTouched();
    const emailArray = this.form.get('emails') as FormArray;
    const emails = emailArray.controls.filter(control => (control as FormGroup).controls.email.valid).map(control => control.value.email);
    this.formValid.emit(emails.length === emailArray.controls.length);
    if (JSON.stringify(emails.sort()) !== JSON.stringify(this.emails.sort())) {
      this.emails = emails;
      this.emailsUpdated.emit(emailArray.controls.filter(control => control.valid).map(control => control.value.email));
    }
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  // OLD CODE - NO FORMS
  emit() {
    this.emailsUpdated.emit(this.emails);
  }

  add() {
    this.emails.push('');
    this.emit();
  }

  remove(value) {
    this.emails = this.emails.filter(v => v !== value);
    this.emit();
  }
}
