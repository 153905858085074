import { Component, Input, OnInit } from '@angular/core';
import { AuthService, AvailabilityComponent, GungModalService } from 'gung-standard';
import { first } from 'rxjs';
import { OttoOlsenAvailabilityHelperService } from '../../services/otto-olsen-availability-helper.service';

@Component({
  selector: 'otto-olsen-availability',
  templateUrl: './otto-olsen-availability.component.html',
  styleUrls: ['./otto-olsen-availability.component.scss']
})
export class OttoOlsenAvailabilityComponent extends AvailabilityComponent {
  @Input()
  unit?: string;

  @Input()
  details = false;

  tooltip: string;

  addicionalInfo: string;

  @Input()
  decimals?: number;

  @Input()
  showOnlyIcons = false;

  @Input()
  productionDays: number;

  @Input()
  quantityInCart?: number;

  constructor(
    protected availabilityHelper: OttoOlsenAvailabilityHelperService,
    protected gungModalService: GungModalService
  ) {
    super(availabilityHelper, gungModalService);
  }

  ngOnChanges() {
    this.availabilityHelper
      .getAvailabilityDisplay(this.availability, null, this.unit, this.details, this.decimals, this.productionDays)
      .pipe(first())
      .subscribe(d => {
        this.status = d.value;
        if (d.class.available && this.quantityInCart > this.availability.currentStock) {
          this.class = { warn: true };
        } else {
          this.class = d.class;
        }
        this.tooltip = d.tooltip;
        this.addicionalInfo = d.addicionalInfo;
      });
  }

  openModal() {
    const availabilities = { ...this.availability?.extra?.availabilities };
    this.gungModalService
      .openAvailabilityModal(
        { ...this.availability, extra: { ...this.availability.extra, availabilities, unit: this.unit } },
        { size: 'lg' },
        this.product
      )
      .then(
        result => { },
        reason => { }
      );
  }
}
