import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  AuthService,
  BuyBtnComponent,
  CartService,
  GungFlowService,
  GungModalService,
  Product,
  ProductInputQuantityConfigService
} from 'gung-standard';
import { filter, first, takeUntil } from 'rxjs';
import { environment } from './../../../environments/environment';

@Component({
  selector: 'otto-olsen-buy-btn',
  templateUrl: './otto-olsen-buy-btn.component.html',
  styleUrls: ['./otto-olsen-buy-btn.component.scss']
})
export class OttoOlsenBuyBtnComponent extends BuyBtnComponent implements OnInit, OnChanges {
  public requestPriceEmail = environment.requestPriceEmail;
  showAddBtn = true;

  @Input()
  stepAmount: number;

  @Input()
  activeInB2B: boolean; // Buy Button = Blue if product is active in B2B=false

  @Input()
  unit: string;

  @Input()
  disabled: boolean;

  @Input()
  originalSize: number; // used to cutting lenght - ar.artfsgforp

  isAnonymous = true;
  public isViewOnly = false;
  isUser = false;

  limitOrderErrorMessage: boolean;

  isCalculatePrice: boolean = false;

  constructor(
    protected cartService: CartService,
    protected gungFlowService: GungFlowService,
    protected gungModalService: GungModalService,
    protected translationService: TranslateService,
    protected authService: AuthService,
    protected productInputQuantityConfigService: ProductInputQuantityConfigService
  ) {
    super(
      cartService,
      gungFlowService,
      productInputQuantityConfigService,
      gungModalService,
      translationService,
      authService
    );
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.decimals && !this.stepAmount) {
      let stepAmount = '0.0000000001';
      stepAmount = stepAmount.substring(0, 2) + stepAmount.substring(stepAmount.length - this.decimals);
      this.stepAmount = +parseFloat(stepAmount);
    }

    this.authService
      .getRoles()
      .pipe(
        filter(roles => !!roles),
        first()
      )
      .subscribe(roles => {
        if (roles.indexOf('ANONYMOUS') < 0) {
          this.isAnonymous = false;
        }
        if (roles.indexOf('USER') > -1 && roles.length === 1) {
          this.isUser = true;
        }
        if (roles.indexOf('VIEW') > -1) {
          this.isViewOnly = true;
        }

        // if (!this.isUser) {
        //   this.stepAmount = this.minimumOrderQuantity = this.originalSize = 1;
        // }
      });

    if (this.product?.extra.ar?.konfigartnr && (this.product.extra.ar.konfigartnr !== '' || this.product.extra.ar.konfigartnr !== null)) {
      this.isCalculatePrice = true;
    }

    if (this.isUser && !this.activeInB2B) {
      this.activeInB2B = !!this.product.extra.templateProductId;
    }
  }

  isConfigureProduct(product: Product): boolean {
    return product.extra?.ar?.webarttext === 'configure';
  }

  goToDetailUrl(): string {
    if (this.isConfigureProduct(this.product)) {
      const templateProductId = this.product.extra.templateProductId;
      return `/product/${templateProductId}`;
    }
    if (this.product.extra.assortmentParentPath) {
      return '/articles/' + this.product.extra.assortmentParentPath;
    }
    return `/product/${this.id}`;
  }

  ngOnChanges(): void {
    // super.ngOnChanges(changes); // ngOnChanges removed on SUPER
  }

  initQtySubscription(): void {
    this.cartService
      .getProductQty(this.id, this.productPartialId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(qty => {
        setTimeout(() => {
          if (qty > 0) {
            this.showAddBtn = false;
          } else {
            this.showAddBtn = true;
          }
          this.currentQty = qty;
          this.previousQty = qty;
          this.quantityUpdatedBuyBtn.emit({ id: this.id, qty: this.currentQty }); // WHY SET TIMEOUT here?????
        }, 0);
      });
  }

  checkModulus(value: number, precision: number): { multiple: boolean; remainder: number } {
    const result = { multiple: false, remainder: 0 };
    // result.multiple = Math.round(value / precision) / (1 / precision) === value;
    // result.multiple = Number.isInteger(value / precision);
    result.multiple = Number.isInteger(Number((value / precision).toFixed(this.decimals || 5)));
    // let remainder = Math.round(value / precision) / (1 / precision);
    let remainder = Number((Math.round(value / precision) / (1 / precision)).toFixed(this.decimals));
    if (remainder < value) {
      remainder = remainder + precision;
    }
    result.remainder = remainder;
    return result;
  }

  set(value: number, ele?: HTMLInputElement) {
    this.limitOrderErrorMessage = false;

    if (typeof value === 'string') {
      value = Number(value);
    }
    value = +value.toFixed(this.decimals);
    this.currentQty = value;
    const checkModulus = this.checkModulus(value, this.stepAmount);
    // if (value >= this.minimumOrderQuantity && (this.stepAmount === 1 || value % this.stepAmount === 0)) {
    if (
      !this.productInputQuantityConfigService.getAllowPurchaseOverAvailability(
        value,
        this.availability,
        this.product,
        this.roles
      )
    ) {
      this.limitOrderErrorMessage = true;
      value = this.previousQty;
      this.cartService.setProductQuantity(this.id, value, this.targetStockId, this.productPartialId);
    } else if (this.allowManualQtyInputOutsideStepSizes) {
      this.cartService.setProductQuantity(this.id, value, this.targetStockId, this.productPartialId);
    } else if (value >= this.minimumOrderQuantity && (this.stepAmount === 1 || checkModulus.multiple)) {
      this.cartService.setProductQuantity(this.id, value, this.targetStockId, this.productPartialId);
      // } else if (value >= 0 && value % this.stepAmount !== 0) {
    } else if (value >= 0 && checkModulus.multiple) {
      // value += (this.stepAmount - (value % this.stepAmount));
      value += checkModulus.remainder;
      this.cartService.setProductQuantity(this.id, value, this.targetStockId, this.productPartialId);
    } else if (value >= 0 && checkModulus.remainder >= this.stepAmount) {
      value = checkModulus.remainder;
      this.cartService.setProductQuantity(this.id, value, this.targetStockId, this.productPartialId);
    } else {
      if (this.checkoutCart) {
        this.gungModalService
          .openConfirmYesNoModal(undefined, this.translationService.instant('REMOVE_PRODUCT') + '?', { size: 'sm' })
          .then(result => {
            if (result) {
              this.cartService.setProductQuantity(this.id, 0, this.targetStockId, this.productPartialId);
            } else {
              this.cartService.setProductQuantity(this.id, this.previousQty, this.targetStockId, this.productPartialId);
            }
          });
      } else {
        this.cartService.setProductQuantity(this.id, 0, this.targetStockId, this.productPartialId);
      }
    }

    if (ele) {
      ele.value = isNaN(value) ? '' : value.toString();
    }
  }

  add() {
    this.limitOrderErrorMessage = false;

    if (!this.currentQty) {
      const toAdd = Math.max(this.minimumOrderQuantity, this.stepAmount, this.recomendedInitOrderQuantity);
      if (
        !this.productInputQuantityConfigService.getAllowPurchaseOverAvailability(
          toAdd,
          this.availability,
          this.product,
          this.roles
        )
      ) {
        this.limitOrderErrorMessage = true;
        return;
      }
      this.cartService.addToCart(this.id, toAdd, this.targetStockId, this.productPartialId, undefined, undefined, undefined, this.product.name);
      return;
    }

    const differenceToStep = this.stepAmount - (this.currentQty % this.stepAmount);
    if (differenceToStep !== this.stepAmount) {
      if (
        !this.productInputQuantityConfigService.getAllowPurchaseOverAvailability(
          this.currentQty + differenceToStep,
          this.availability,
          this.product,
          this.roles
        )
      ) {
        this.limitOrderErrorMessage = true;
        return;
      }
      this.cartService.addToCart(this.id, differenceToStep, this.targetStockId, this.productPartialId, undefined, undefined, undefined, this.product.name);
      return;
    }

    if (
      !this.productInputQuantityConfigService.getAllowPurchaseOverAvailability(
        this.currentQty + this.stepAmount,
        this.availability,
        this.product,
        this.roles
      )
    ) {
      this.limitOrderErrorMessage = true;
      return;
    }
    this.cartService.addToCart(this.id, this.stepAmount, this.targetStockId, this.productPartialId, undefined, undefined, undefined, this.product.name);
  }

  sub() {
    this.limitOrderErrorMessage = false;
    super.sub();
  }
}
